import setting from './settings'

export default (() => {
    return {
        //Login
        LOGIN: setting.api.url + 'user/loginAsAdmin',
        VALIDATE_OTP: setting.api.url + 'user/validateForgetPassOtp',
        FORGOT_PASSWORD: setting.api.url + 'user/forgotPassword',
        RESET_PASSWORD: setting.api.url + 'user/resetPassword',
        LOGOUT: setting.api.url + 'user/logout',
        // Beneficiary
        USER_BENEFICIARY_LIST: setting.api.url + 'user/getUserBeneficiary',
        //User
        CREATE_USER: setting.api.url + 'user/addUser',
        EDIT_USER: setting.api.url + 'user/editUser',
        EDIT_USER_ADDRESS: setting.api.url + 'user/editUserAddress',
        USER_LIST: setting.api.url + 'user/userList',
        DELETE_USER: setting.api.url + 'user/deleteUser',
        USER_PROFILE: setting.api.url + 'user/userDetails',
        ADD_OTHER_BANK_DETAILS: setting.api.url + 'user/addOtherBankDetails',
        DELETE_OTHER_BANK_DETAILS: setting.api.url + 'user/deleteOtherBankDetails',
        //Account
        CREATE_ACCOUNT: setting.api.url + 'account/create',
        ACCOUNT_LIST: setting.api.url + 'account/list',
        DELETE_ACCOUNT: setting.api.url + 'account/delete',
        EDIT_ACCOUNT: setting.api.url + 'account/edit',
        // Customer Account
        CREATE_CUSTOMER_ACCOUNT: setting.api.url + 'customerAccount/create',
        ADD_BALANCE: setting.api.url + 'customerAccount/addBalance',
        CUSTOMER_ACCOUNT_LIST: setting.api.url + 'customerAccount/list',
        EDIT_CUSTOMER_ACCOUNT_STATUS: setting.api.url + 'customerAccount/delete',
        DELETE_CUSTOMER_ACCOUNT: setting.api.url + 'customerAccount/delete',
        EDIT_CUSTOMER_ACCOUNT: setting.api.url + 'customerAccount/editAccount',
        GET_STATEMENT: setting.api.url + 'transaction/getStatement',
        // Online Services
        ONLINE_SERVICES_LIST: setting.api.url + 'onlineServices/list',
        ONLINE_SERVICES_EDIT: setting.api.url + 'onlineServices/edit',
        // Services
        CREATE_SERVICES: setting.api.url + 'service/create',
        CREATE_ONLINE_SERVICE_OPTION: setting.api.url + 'service/createOptions',
        SERVICE_LIST: setting.api.url + 'service/list',
        DELETE_SERVICE: setting.api.url + 'service/deleteService',
        EDIT_SERVICE: setting.api.url + 'service/edit',
        SERVICE_OPTION_EDIT: setting.api.url + 'service/editOptionAction',
        // Transaction
        TRANSACTION_LIST: setting.api.url + 'transaction/listByAdmin',
        EDIT_TRANSACTION: setting.api.url + 'transaction/editTransaction',
        // Dashboard
        ADMIN_DASHBOARD: setting.api.url + 'user/getAdminDashboard',
        // Settings
        GET_CURRENT_VALUE: setting.api.url + 'user/getSharePrice',
        ADD_CURRENT_VALUE: setting.api.url + 'user/addSharePrice',
        ADD_SEQUENCE: setting.api.url + 'user/addSequence',
        EDIT_SEQUENCE: setting.api.url + 'user/editSequence',
        EDIT_CURRENT_VALUE: setting.api.url + 'user/editSharePrice',
        // Grievances
        GET_GRIEVANCES_LIST: setting.api.url + 'grievances/list',
        EDIT_GRIEVANCES_LIST: setting.api.url + 'grievances/edit',
        DELETE_GRIEVANCES_LIST: setting.api.url + 'grievances/delete',
    }
})()
