const LOGIN_REQUEST = 'LOGIN_REQUEST'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_FAILURE = 'LOGIN_FAILURE'

const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'

const CREATE_CUSTOMER_ACCOUNT_SUCCESS = 'CREATE_CUSTOMER_ACCOUNT_SUCCESS'
const CREATE_CUSTOMER_ACCOUNT_FAILURE = 'CREATE_CUSTOMER_ACCOUNT_FAILURE'

const CREATE_ONLINE_SERVICE_SUCCESS = 'CREATE_ONLINE_SERVICE_SUCCESS'
const CREATE_ONLINE_SERVICE_FAILURE = 'CREATE_ONLINE_SERVICE_FAILURE'

const CREATE_ONLINE_SERVICE_OPTION_SUCCESS = 'CREATE_ONLINE_SERVICE_OPTION_SUCCESS'
const CREATE_ONLINE_SERVICE_OPTION_FAILURE = 'CREATE_ONLINE_SERVICE_OPTION_FAILURE'

const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE'

const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST'
const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'



export {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,

    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,

    CREATE_CUSTOMER_ACCOUNT_SUCCESS,
    CREATE_CUSTOMER_ACCOUNT_FAILURE,

    GET_USER_LIST_REQUEST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE,

    CREATE_ACCOUNT_SUCCESS,
    CREATE_ACCOUNT_FAILURE,

    CREATE_ONLINE_SERVICE_SUCCESS,
    CREATE_ONLINE_SERVICE_FAILURE,

    CREATE_ONLINE_SERVICE_OPTION_SUCCESS,
    CREATE_ONLINE_SERVICE_OPTION_FAILURE,

}
