import {
  CREATE_CUSTOMER_ACCOUNT_SUCCESS,
  CREATE_CUSTOMER_ACCOUNT_FAILURE,
} from "../_actions/actionType";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const createCustomerAccountReducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        loading: false,
        data:action.payload,
        error: "",
      };
    case CREATE_CUSTOMER_ACCOUNT_FAILURE:
      return {
        loading: false,
        data:[],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default createCustomerAccountReducer;