import {  Alert, Snackbar, Stack } from "@mui/material"

export const Toastify = (props) => {
    return (
            <Stack>
                    <Snackbar open={props.resMsgAdd} onClose={props.handleClose} autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.text} Created Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgAdd1} onClose={props.handleClose} autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.text1} Created Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgTransaction} onClose={props.handleClose} autoHideDuration={3000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            Transaction Successful
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgDelete} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error">
                            {props.text} Deleted Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgEdit} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.text} Edited Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgEdit1} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.text1} Edited Successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resUpdate} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.responseMessageUpdate}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgError} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error">
                            {props.responseMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsgError1} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error">
                            {props.responseMessag1}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={props.resMsg} onClose={props.handleClose} autoHideDuration={3000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success">
                            {props.text} Successfully
                        </Alert>
                    </Snackbar>
            </Stack>
        )
}

