import { CircularProgress } from "@mui/material"
import { TableLoaderStyle, LoaderStyle, DashboardLoaderStyle } from '../muiStyle/MUIstyle'

export const TableLoader = (props) => {
    return (
        <TableLoaderStyle>
            <CircularProgress color="inherit" />
        </TableLoaderStyle>
    )
}
export const Loader = (props) => {
    return (
        <LoaderStyle
            open={true}
        >
            <CircularProgress color="inherit" />
        </LoaderStyle>
    )
}
export const DashboardLoader = (props) => {
    return (
        <DashboardLoaderStyle>
            <CircularProgress color="inherit" />
        </DashboardLoaderStyle>
    )
}
