import React from 'react'
import { Box, Fade, Modal } from "@mui/material"
import { logoutAdmin } from '../../services'
import { LogoutButton } from '../../components/button/Button'
import { Cookies } from 'react-cookie'
import { STATUS_ONE, STATUS_TWO } from '../../components/helpers/Constants'

function Logout(props) {
    const cookies = new Cookies()
    const handleLogout = () => {
        props.setLoader(true)
        logoutAdmin().then((res) => {
                if (res.data.statusCode === STATUS_ONE) {
                    cookies.remove('accessToken')
                    cookies.remove('userType')
                    cookies.remove('userName')
                    document.location.href = ('/')
                } else {
                    props.setResMsgError(true)
                    if(res.data.error.errorCode === STATUS_TWO){
                        cookies.remove('accessToken')
                        cookies.remove('userType')
                        cookies.remove('userName')
                        document.location.href = ('/')
                    }
                    else if (res.data.error.errors) {
                        props.setResponseMessage(res.data.error.errors[0].message)
                    }
                    else {
                        props.setResponseMessage(res.data.error.responseMessage)
                    }
                }
        })
    }


  return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-class"
            open={props.openModal}
            onClose={props.handleClose}
            closeAfterTransition
        >
        <Fade in={props.openModal}>
            <Box className="paper-class">
                <Box>
                    <Box className="form-header">
                        <h2>LOGOUT</h2>
                    </Box>
                    <Box className='form-header' >
                            <p>ARE YOU SURE YOU WANT TO LOGOUT?</p>
                    </Box>
                    <LogoutButton
                            text='Logout'
                            text1='Cancel'
                            handleSubmit={handleLogout}
                            handleBack={props.handleClose}
                    />
                </Box>
            </Box>
        </Fade>
    </Modal>

  )
}

export default Logout