import { Box, Button, styled } from "@mui/material"

export const CreateB = styled(Button)(({ theme }) => ({
    backgroundColor: '#ceb23c',
    color: 'black',
    borderRadius: '2px',
    padding: '7px',
    minWidth: '7rem',
}))

export const BackB = styled(Button)(({ theme }) => ({
    backgroundColor: '#d2cbcb',
    color: 'black',
    borderRadius: '2px',
    padding: '7px',
    width: '7rem',
    marginLeft: '1rem'
}))

export const AuthCreateB = styled(Button)(({ theme }) => ({
    backgroundColor: '#ceb23c',
    color: 'white',
    borderRadius: '2px',
    padding: '7px',
    minWidth: '7rem',

}))

export const AuthBackB = styled(Button)(({ theme }) => ({
    backgroundColor: '#d2cbcb',
    color: 'white',
    borderRadius: '2px',
    padding: '7px',
    width: '7rem',
    marginLeft: '1rem'
}))


export const BoxStyleB = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '1rem'
}))

export const PreviewB = styled(Button)(({ theme }) => ({
    marginLeft: '1rem',
    marginBottom:'2rem',
    backgroundColor: '#d2cbcb',
    color: 'black',
}))

export const SortButton = styled(Button)(({ theme }) => ({
    color: 'white'
}))

export const AddOtherBankButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'purple',
    color: 'black',
    borderRadius: '2px',
    padding: '7px',
    width: '5rem'
}))

