export const development = {
    api: {
        url: process.env.REACT_APP_DEVELOPMENT,
        Auth: process.env.REACT_APP_ENV
    },

}
export const staging = {
    api: {
        url: '',
        Auth: '',
    },
}

export const production = {
    api: {
        url: process.env.REACT_APP_PRODUCTION,
        Auth: process.env.REACT_APP_PROD
    },
}