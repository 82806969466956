import { FormControl, styled, TextField, Box, Divider, Backdrop, FormHelperText, Card, Paper } from "@mui/material";

export const Formcontrol = styled(FormControl)(() => ({
    width: '15rem',
    marginLeft: '1rem',
    marginTop: '1rem'
}))

export const Formcontrol1 = styled(FormControl)(() => ({
    width: '15rem',
    marginRight: '1rem',
}))

export const Formcontrol2 = styled(FormControl)(() => ({
    width: '15rem',
}))

export const Formcontrol3 = styled(FormControl)(() => ({
    width: '15rem',
    margin:'0px 1rem'
}))

export const TableLoaderStyle = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '28rem'
}))

export const LoaderStyle = styled(Backdrop)(() => ({
    color: '#fff',
    zIndex: (theme) => theme.zIndex.drawer + 1
}))

export const DashboardLoaderStyle = styled(Box)(() => ({
    display: 'flex', alignItems: 'center',
    justifyContent: 'center',
    height: '45rem'
}))

export const RemarkBox = styled(TextField)(() => ({
    margin: '0px 1rem'
}))

export const DividerStyle = styled(Divider)(() => ({
    marginBottom: '1rem'
}))

export const BoxHeading = styled(Box)(() => ({
    margin: '5.5rem auto 1rem',

    fontWeight: '500',
    lineHeight: '1.2rem',
    fontSize: 'xx-large', color: '#a5a1a1',
    fontFamily: 'Poppins',
    textTransform: 'uppercase'
}))

export const UploadLogoError = styled(FormHelperText)(() => ({
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.5rem'
}))

export const HelperTextError = styled(FormHelperText)(() => ({
    color: 'red',
}))

export const DashboardCard = styled(Card)(() => ({
    marginLeft: '2rem',
    width: '49%',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
}))

export const DashboardAccountCard = styled(Card)(() => ({
    width: '49%',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
}))

export const ProfileCard = styled(Card)(() => ({
    width: '14rem', 
    height: '14rem', 
    borderRadius:'45%' 
}))

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))




