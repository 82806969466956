import {
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS
} from "../_actions/actionType"

const iState = {
    userData: {}
}
export const loginReducer = (state = iState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            state.userData = action.payload
            return {
                ...state
            }
        case LOGIN_FAILURE:
            state.userData = action.payload
            return {
                ...state
            }
        case LOGIN_REQUEST:
            state.userData = action.payload
            return {
                ...state
            }
        default:
            return { ...state }
    }
}


