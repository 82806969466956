import React, { Suspense } from 'react'
import { Cookies } from 'react-cookie'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppSidebar from './components/sidebar/AppSidebar'
import PrivateRoutes from './routes/PrivateRoutes'

const loading = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
)


// Pages
const Login = React.lazy(() => import('./view/login/Login'))
const ForgotPassword = React.lazy(() => import('./view/login/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./view/login/ResetPassword'))
const ValidatePassword = React.lazy(() => import('./view/login/ValidatePassword'))
const Account = React.lazy(() => import('./view/account/Account'))
const Dashboard = React.lazy(() => import('./view/dashboard/Dashboard'))
const CustomerAccount = React.lazy(() => import('./view/customerAccount/CustomerAccount'))
const Transaction = React.lazy(() => import('./view/transaction/Transaction'))
const User = React.lazy(() => import('./view/user/User'))
const CreateUser = React.lazy(() => import('./view/user/CreateUser'))
const EditUser = React.lazy(() => import('./view/user/EditUser'))
const UserProfile = React.lazy(() => import('./view/user/UserProfile'))
const CreateAccount = React.lazy(() => import('./view/account/CreateAccount'))
const CreateCustomerAccount = React.lazy(() => import('./view/customerAccount/CreateCustomerAccount'))
const ViewStatement = React.lazy(() => import('./view/customerAccount/ViewStatement'))
const Settings = React.lazy(() => import('./view/settings/Settings'))
const NewTransaction = React.lazy(() => import('./view/transaction/NewTransaction'))
const OnlineServices = React.lazy(() => import('./view/onlineServices/OnlineServices'))
const AllServices = React.lazy(() => import('./view/onlineServices/AllServices'))
const ServiceDetail = React.lazy(() => import('./view/onlineServices/ServiceDetails'))
const CreateNewService = React.lazy(() => import('./view/onlineServices/CreateNewService'))
const Grievance = React.lazy(() => import('./view/grievance/Grievance'))
const cookies = new Cookies();
const App = () => {
    const auth = cookies.get('accessToken')
    return (
            <>
                {auth==='' || auth===null || auth===undefined ?
                        <BrowserRouter>
                            <Suspense fallback={loading}>
                                <Routes>
                                    <Route exact path="*" name="Login Page"
                                           element={!auth ? <Login/>:<Navigate to="/"/>}/>
                                    <Route exact path="/forgot-password" name="Forget Password"
                                           element={<ForgotPassword/>}/>
                                    <Route exact path="/validate-password" name="Validate"
                                           element={<ValidatePassword/>}/>
                                    <Route exact path="/reset-password" name="Reset Password"
                                           element={<ResetPassword/>}/>
                                </Routes>
                            </Suspense>
                        </BrowserRouter>
                        :
                        <>
                            <div className="main-container">
                                <BrowserRouter>
                                    <div className="main-sidebar">
                                        <AppSidebar/>
                                    </div>
                                    <div className="main-app-body">

                                        <Suspense fallback={loading}>

                                            <Routes>
                                                <Route path="/" name="Home and Dashboard"
                                                       element={<PrivateRoutes Component={Dashboard}/>}/>
                                                <Route path="/account" name="Account"
                                                       element={<PrivateRoutes Component={Account}/>}/>
                                                <Route path="/create-account" name="Create Account"
                                                       element={<PrivateRoutes Component={CreateAccount}/>}/>

                                                <Route path="/user-account" name="User Account"
                                                       element={<PrivateRoutes Component={CustomerAccount}/>}/>
                                                <Route path="/create-user-account" name="Create User Account"
                                                       element={<PrivateRoutes Component={CreateCustomerAccount}/>}/>
                                                 <Route path="/customer-account/view-statement" name="View Statement"
                                                       element={<PrivateRoutes Component={ViewStatement}/>}/>

                                                <Route path="/transaction" name="Transaction"
                                                       element={<PrivateRoutes Component={Transaction}/>}/>
                                                <Route path="/new-transaction" name="New Transaction"
                                                       element={<PrivateRoutes Component={NewTransaction}/>}/>

                                                <Route path="/user" name="User"
                                                       element={<PrivateRoutes Component={User}/>}/>
                                                <Route path="/user/create-user" name="Create User"
                                                       element={<PrivateRoutes Component={CreateUser}/>}/>
                                                <Route path="/user/edit-user" name="Edit User"
                                                       element={<PrivateRoutes Component={EditUser}/>}/>
                                                <Route path="/user/user-profile/:id" name="Create User"
                                                       element={<PrivateRoutes Component={UserProfile}/>}/>

                                                <Route path="/settings" name="Settings"
                                                       element={<PrivateRoutes Component={Settings}/>}/>

                                                 <Route path="/grievance" name="Grievance"
                                                       element={<PrivateRoutes Component={Grievance}/>}/>

                                                <Route path="/online-services" name="Online Services"
                                                       element={<PrivateRoutes Component={OnlineServices}/>}/>
                                                <Route path="/online-service/details/:id" name="Service Details"
                                                       element={<PrivateRoutes Component={ServiceDetail}/>}/>
                                                <Route path="/all-services" name="All Online Services"
                                                       element={<PrivateRoutes Component={AllServices}/>}/>
                                                <Route path="/add-new-service" name="Create Online Service"
                                                       element={<PrivateRoutes Component={CreateNewService}/>}/>
                                            </Routes>
                                        </Suspense>
                                    </div>
                                </BrowserRouter>

                            </div>
                            <div className="routing">

                            </div>
                        </>

                }
            </>

    )
}

export default App
