import { development, production, staging } from './env'

export default (() => {
    switch (process.env.REACT_APP_ENV) {
        case 'dev':
        case 'development':
            return development
        case 'staging':
            return staging
            case 'prod':
                case 'production':
                    return production        
        default:
            return development
    }
})()
