import { combineReducers } from 'redux'
import createCustomerAccountReducer from './createCustomerAccountReducer'
import createUserReducer from './createUserReducer'
import { getUserList } from './getUserListReducer'
import { loginReducer } from './loginReducer'

const rootReducer = combineReducers({
    userDetails: loginReducer,
    createUser: createUserReducer,
    createCutomerAccount: createCustomerAccountReducer,
    userList: getUserList,
})

export default rootReducer
