import {
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
} from "../_actions/actionType";

const initialState = {
    loading: false,
    data: [],
    error: "",
  };

const createUserReducer = (state = initialState, action) => {
    switch (action.type) {  
      case CREATE_USER_SUCCESS:
        return {
          loading: false,
          data:action.payload,
          error: "",
        };
      case CREATE_USER_FAILURE:
        return {
          loading: false,
          data:[],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  export default createUserReducer;