import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import './header.css'
import { DrawerData } from './DrawerData'
import { Loader } from '../loader/Loader'
import {Toastify} from '../toast/Toastify'
import { Avatar, Menu, MenuItem } from '@mui/material'
import { USER_NAME } from "../helpers/Constants"
import LogoutIcon from '@mui/icons-material/Logout'
import Logout from '../../view/logout/Logout'

const drawerWidth = 250

export default function AppSidebar(props) {
    const { window } = props
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [resMsgError, setResMsgError] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [openPopover, setOpenPopover] = useState(false)
    const [openLogout, setOpenLogout] = useState(false) 
    const handleDrawerToggle = () => {
        setOpen(!open)
    }
    const container = window!==undefined ? () => window().document.body:undefined

    const handleClose = () => {
        setResMsgError(false)
        setResponseMessage('')
        setOpenPopover(false)
        setOpenLogout(false)
    }

    const handleClick = () => {
        setOpenPopover(true)
    }
    const handleOpenModal =() => {
        setOpenLogout(!openLogout)
    }

    return (
            <Box>
                {loader ?
                        <Loader/>:
                        <>
                            <Toastify
                                    resMsgError={resMsgError}
                                    responseMessage={responseMessage}
                                    handleClose = {handleClose}
                            />
                            <CssBaseline/>
                            <AppBar
                                    position="fixed"
                                    sx={{
                                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                                        ml: { sm: `${drawerWidth}px` }, backgroundColor:'black'
                                    }}
                            >
                                <Toolbar>
                                    <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                            sx={{ mr: 2, display: { sm: 'none' } }}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    <Box className='box-align-end' sx={{ flexGrow: 1}}>
                                        <IconButton
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleClick}
                                                color="inherit"
                                            >
                                            <Avatar src='/broken-image.jpg' sx={{marginRight:'0.5rem'}}/>
                                                        {USER_NAME}
                                        </IconButton>
                                        <Menu 
                                                className='menu'
                                                open={openPopover}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                        >
                                            <MenuItem sx={{width:'13rem', color:'#a5a1a1'}} onClick={handleOpenModal}>
                                                    <LogoutIcon className="icon-sidebar"/>LOGOUT
                                                    <Logout 
                                                        openModal={openLogout}
                                                        handleClose={handleClose}
                                                        setLoader={setLoader}
                                                        setResMsgError={setResMsgError}
                                                        setResponseMessage={setResponseMessage}
                                                    />
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Toolbar>
                            </AppBar>
                            <Box
                                    component="nav"
                                    sx={{
                                        width: { sm: drawerWidth }, flexShrink: { sm: 0 }
                                    }}
                                    aria-label="mailbox folders"
                            >
                                <Drawer
                                        container={container}
                                        variant="temporary"
                                        open={open}
                                        onClose={handleDrawerToggle}
                                        ModalProps={{
                                            keepMounted: true,
                                        }}
                                        sx={{
                                            display: { xs: 'block', sm: 'none' },
                                            '& .MuiDrawer-paper': {
                                                backgroundColor: 'black',
                                                boxSizing: 'border-box',
                                                width: drawerWidth
                                            },
                                        }}
                                >
                                    <DrawerData
                                            setLoader={setLoader}
                                            setResMsgError={setResMsgError}
                                            setResponseMessage={setResponseMessage}
                                    />
                                </Drawer>
                                <Drawer
                                        variant="permanent"
                                        sx={{
                                            display: { xs: 'none', sm: 'block' },
                                            '& .MuiDrawer-paper': {
                                                backgroundColor: 'black',
                                                boxSizing: 'border-box',
                                                width: drawerWidth
                                            },
                                        }}
                                        open
                                >
                                    <DrawerData
                                            setLoader={setLoader}
                                            setResMsgError={setResMsgError}
                                            setResponseMessage={setResponseMessage}
                                    />
                                </Drawer>
                            </Box>
                        </>
                }
            </Box>
    )
}
