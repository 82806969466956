import { useEffect } from 'react'
import { Cookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'


const PrivateRoutes = (props) => {
    const navigate = useNavigate()
    const { Component } = props
    const cookies = new Cookies();
    useEffect(() => {
        let auth = cookies.get('accessToken')
        if (!auth) {
            navigate('/login')
        }
        // eslint-disable-next-line
    }, [])


    return (
            <div>
                <Component/>
            </div>
    )
}

export default PrivateRoutes
