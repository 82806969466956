import moment from "moment";
import { Cookies } from "react-cookie"
const cookies = new Cookies();

export const USER_TYPE = cookies.get('userType')
export const USER_NAME = cookies.get('userName')
export const SUPER_ADMIN = '1'
export const USER = '2'
export const ADMIN = '3'
export const MANAGER = '4'
export const VIEWER = '5'
export const STATUS_ZERO = 0
export const STATUS_ONE = 1
export const STATUS_TWO = 2
export const STATUS_THREE = 3
export const STATUS_FOUR = 4
export const disabledDate = (current) => {
    return current && current > moment().endOf('day')
}