import { GET_USER_LIST_FAILURE, GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS } from "../_actions/actionType"

const iState = {
    data: ''
}
export const getUserList = (state = iState, action) => {
    switch (action.type) {
        case GET_USER_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
            case GET_USER_LIST_FAILURE:
                return {
                    ...state,
                    data: action.payload
                }
                case GET_USER_LIST_REQUEST:
                    return {
                        ...state,
                        data: action.payload
                    }
                    default:
            return state
    }
}


