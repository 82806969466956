import { Box, Button } from "@mui/material"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { AuthBackB, AuthCreateB, BackB, BoxStyleB, CreateB, PreviewB } from "./ButtonStyling"
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

export const CreateButton = (props) => {
    return (
        <Box className="form-input">
            <CreateB onClick={props.data}>Create</CreateB>
        </Box>
    )
}

export const BackButton = (props) => {
    return (
        <BackB onClick={props.handleBack}>
            <ArrowBackRoundedIcon /> Back
        </BackB>
    )
}

export const CancelButton = (props) => {
    return (<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={props.handleBack}>
            <HighlightOffOutlinedIcon />
        </Button>
    </Box>

    )
}

export const EditButton = (props) => {
    return (
        <BoxStyleB>
            <CreateB onClick={props.handleEdit}>Edit</CreateB>
            <BackB onClick={props.handleClose}>Cancel</BackB>
        </BoxStyleB>
    )
}

export const AddButton = (props) => {
    return (
        <BoxStyleB>
            <CreateB onClick={props.handleAdd}>Add</CreateB>
            <BackB onClick={props.handleClose}>Cancel</BackB>
        </BoxStyleB>
    )
}

export const AuthButton = (props) => {
    return (
        <Box className="form-check">
            <BoxStyleB>
                <AuthCreateB onClick={props.handleSubmit}>{props.text}</AuthCreateB>
                <AuthBackB onClick={props.handleBack}>Back</AuthBackB>
            </BoxStyleB>
        </Box>
    )
}

export const LogoutButton = (props) => {
    return (
        <Box className="form-check">
            <BoxStyleB>
                <CreateB onClick={props.handleSubmit}>{props.text}</CreateB>
                <BackB onClick={props.handleBack}>{props.text1}</BackB>
            </BoxStyleB>
        </Box>
    )
}