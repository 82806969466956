import React, { useState } from 'react'
import { Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@mui/material"
import { Link } from "react-router-dom"
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export const DrawerData = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(false)
    const handleListItemClick = (
        event,
        index,
    ) => {
        setSelectedIndex(index)
    }

    return (
        <div>
            <Toolbar className="title1">
                <div className="sidebar">
                    <div>
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.26052 0C1.90903 0 0 1.92651 0 4.30759V7.18292H21.5203C24.225 7.18292 26.4195 4.96419 26.4195 2.22956V0H4.26052Z"
                                fill="#CEB23C" />
                            <path d="M0.00366211 14.907V17.0897H12.4177C15.151 17.0897 17.3669 14.8493 17.3669 12.0858V9.91034H4.9493C2.216 9.91034 0.00366211 12.1471 0.00366211 14.907Z"
                                fill="#CEB23C" />
                            <path d="M0.00366211 24.7849V27H4.51752C7.365 27 9.6701 24.6658 9.6701 21.7905V19.8207H4.91361C2.20172 19.8171 0.00366211 22.0394 0.00366211 24.7849Z"
                                fill="white" />
                        </svg>
                        <text>Fedora Banking</text>
                    </div>
                </div>
            </Toolbar>
            <Divider />
            <List>
                <Link to="/" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)} className="link-bg">
                        <ListItemIcon>
                            <HomeTwoToneIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                </Link>

                <Link to="/user" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}>
                        <ListItemIcon>
                            <AccountCircleTwoToneIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="User" />
                    </ListItem>
                </Link>

                <Link to="/user-account" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="User Account" />
                    </ListItem>
                </Link>

                <Link to="/account" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}>
                        <ListItemIcon>
                            <ManageAccountsOutlinedIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Account" />
                    </ListItem>
                </Link>

                <Link to="/transaction" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}>
                        <ListItemIcon>
                            <ReceiptLongTwoToneIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Transaction" />
                    </ListItem>
                </Link>

                <Link to="/settings" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}>
                        <ListItemIcon>
                            <BuildOutlinedIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Setting" />
                    </ListItem>
                </Link>

                <Link to="/grievance" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 6}
                        onClick={(event) => handleListItemClick(event, 6)}>
                        <ListItemIcon>
                            <ReportProblemOutlinedIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Grievance" />
                    </ListItem>
                </Link>

                <Link to="/online-services" className="link-sidebar">
                    <ListItem button selected={selectedIndex === 7}
                        onClick={(event) => handleListItemClick(event, 7)}>
                        <ListItemIcon>
                            <ReceiptTwoToneIcon className="icon-sidebar" />
                        </ListItemIcon>
                        <ListItemText primary="Online Services" />
                    </ListItem>
                </Link>
            </List>
            <Divider />
        </div>

    )
}