import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './config/store'
import { CookiesProvider } from 'react-cookie'


ReactDOM.render(
        <React.StrictMode>
            <CookiesProvider>
                <Provider store={store}>
                    <App/>
                </Provider> 
            </CookiesProvider>
        </React.StrictMode>,
        document.getElementById('root')
)

reportWebVitals()
