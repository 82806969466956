import axios from 'axios'
import { Cookies } from 'react-cookie'
import { resetPasswordAuth } from '../auth/Auth'
import Api from '../config/Api'
const cookies = new Cookies();

const accessToken = cookies.get('accessToken')
axios.defaults.headers.common['accessToken'] = accessToken
axios.defaults.headers.common[
        'authorization'
] = `${process.env.REACT_APP_BASIC_AUTH}`

// ************Post************//
// Login
export const loginAdmin = (params) => axios.post(`${Api.LOGIN}`, params)
// User
export const createUser = (params) => axios.post(`${Api.CREATE_USER}`, params)
export const addOtherBankDetails = (params) => axios.post(`${Api.ADD_OTHER_BANK_DETAILS}`, params)
export const validateOtp = (params) => axios.post(`${Api.VALIDATE_OTP}`, params)
// Account
export const createAccount = (params) => axios.post(`${Api.CREATE_ACCOUNT}`, params)
// Customer Account
export const createCustomerAccount = (params) => axios.post(`${Api.CREATE_CUSTOMER_ACCOUNT}`, params)
export const addBalance = (params) => axios.post(`${Api.ADD_BALANCE}`, params)
// Settings
export const addCurrentValue = (params) => axios.post(`${Api.ADD_CURRENT_VALUE}`, params)
export const addSequence = (params) => axios.post(`${Api.ADD_SEQUENCE}`, params)
// Services
export const createOnlineServices = (params) => axios.post(`${Api.CREATE_SERVICES}`, params)
export const createOnlineServiceOption = (params) => axios.post(`${Api.CREATE_ONLINE_SERVICE_OPTION}`, params)

// ************Get************//
// Admin Dashboard
export const getAdminDashboard = () => axios.get(`${Api.ADMIN_DASHBOARD}`)
//User
export const userList = (params) => {
        const url = new URL(`${Api.USER_LIST}?`);
        const searchParams = url.searchParams;
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.count && searchParams.set('count', params.count)
        params.status && searchParams.set('status', params.status)
        params.search && searchParams.set('search', params.search)
        params.sortField && searchParams.set('sortField', params.sortField)
        params.sortType && searchParams.set('sortType', params.sortType)
        params.UserType && searchParams.set('UserType', params.UserType)
        params.customerId && searchParams.set('customerId', params.customerId)
        return axios.get(`${url}`)
}
export const userProfile = (params) => {
        const url = new URL(`${Api.USER_PROFILE}?`);
        const searchParams = url.searchParams;
        params.customerId && searchParams.set('customerId', params.customerId)
        return axios.get(`${url}`)
}
// Beneficiary
export const userbeneficiaryList = (params) => {
        const url = new URL(`${Api.USER_BENEFICIARY_LIST}?`);
        const searchParams = url.searchParams;
        params.customerId && searchParams.set('customerId', params.customerId)
        return axios.get(`${url}`)
}
// Account
export const accountList = (params) => {
        const url = new URL(`${Api.ACCOUNT_LIST}?`);
        const searchParams = url.searchParams;
        params.accountType && searchParams.set('accountType', params.accountType)
        params.account && searchParams.set('account', params.account)
        return axios.get(`${url}`)
}
// Customer Account
export const getStatement = (params) => {
        const url = new URL(`${Api.GET_STATEMENT}?`);
        const searchParams = url.searchParams;
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.count && searchParams.set('count', params.count)
        params.sortField && searchParams.set('sortField', params.sortField)
        params.sortType && searchParams.set('sortType', params.sortType)
        params.AccountType && searchParams.set('AccountType', params.AccountType)
        params.customerId && searchParams.set('customerId', params.customerId)
        params.accountNumber && searchParams.set('accountNumber', params.accountNumber)
        params.endDate && searchParams.set('endDate', params.endDate)
        params.startDate && searchParams.set('startDate', params.startDate)
        return axios.get(`${url}`)
}

export const customerAccountList = (params) => {
        const url = new URL(`${Api.CUSTOMER_ACCOUNT_LIST}?`);
        const searchParams = url.searchParams;
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.count && searchParams.set('count', params.count)
        params.search && searchParams.set('search', params.search)
        params.sortField && searchParams.set('sortField', params.sortField)
        params.sortType && searchParams.set('sortType', params.sortType)
        params.AccountType && searchParams.set('AccountType', params.AccountType)
        params.searchDay && searchParams.set('searchDay', params.searchDay)
        params.searchMonth && searchParams.set('searchMonth', params.searchMonth)
        params.searchYear && searchParams.set('searchYear', params.searchYear)
        params.accountStatus !== undefined && searchParams.set('accountStatus', params.accountStatus)
        return axios.get(`${url}`)
}
// Online Services
export const getOnlineServicesList = (params) => {
        const url = new URL(`${Api.ONLINE_SERVICES_LIST}?`);
        const searchParams = url.searchParams;
        params.serviceType && searchParams.set('serviceType', params.serviceType)
        params.status && searchParams.set('status', params.status)
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.count && searchParams.set('count', params.count)
        params.search && searchParams.set('search', params.search)
        params.sortField && searchParams.set('sortField', params.sortField)
        params.sortType && searchParams.set('sortType', params.sortType)
        params.searchDay && searchParams.set('searchDay', params.searchDay)
        params.searchMonth && searchParams.set('searchMonth', params.searchMonth)
        params.searchYear && searchParams.set('searchYear', params.searchYear)
        return axios.get(`${url}`)
}
// Services
export const getServiceList = (params) => {
        const url = new URL(`${Api.SERVICE_LIST}?`);
        const searchParams = url.searchParams;
        params.serviceName && searchParams.set('serviceName', params.serviceName)
        params.status && searchParams.set('status', params.status)
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.count && searchParams.set('count', params.count)
        return axios.get(`${url}`)
}
// Transaction
export const getTransactionList = (params) => {
        const url = new URL(`${Api.TRANSACTION_LIST}?`);
        const searchParams = url.searchParams;
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.accountType && searchParams.set('accountType', params.accountType)
        params.endDate && searchParams.set('endDate', params.endDate)
        params.startDate && searchParams.set('startDate', params.startDate)
        params.search && searchParams.set('search', params.search)
        params.sortField && searchParams.set('sortField', params.sortField)
        params.sortType && searchParams.set('sortType', params.sortType)
        params.count && searchParams.set('count', params.count)
        return axios.get(`${url}`)
}
// Settings
export const getCurrentValue = (params) => axios.get(`${Api.GET_CURRENT_VALUE}`)
// Grievance
export const getGrievancesList = (params) => {
        const url = new URL(`${Api.GET_GRIEVANCES_LIST}?`);
        const searchParams = url.searchParams;
        params.status && searchParams.set('status', params.status)
        params.date && searchParams.set('date', params.date)
        params.count && searchParams.set('count', params.count)
        params.pageNo !== undefined && searchParams.set('pageNo', params.pageNo)
        params.search && searchParams.set('search', params.search)
        return axios.get(`${url}`)
}

// ************Put************//
export const editSequence = (params) => axios.put(`${Api.EDIT_SEQUENCE}`, params)
export const editUser = (params) => {
        const url = new URL(`${Api.EDIT_USER}?`);
        const searchParams = url.searchParams;
        params.name && searchParams.set('name', params.name)
        params.email && searchParams.set('email', params.email)
        params.customerId && searchParams.set('customerId', params.customerId)
        params.mobileNo && searchParams.set('mobileNo', params.mobileNo)
        params.userType && searchParams.set('userType', params.userType)
        params.allotedStocks && searchParams.set('allotedStocks', params.allotedStocks)
        params.gender && searchParams.set('gender', params.gender)
        params.DOB && searchParams.set('DOB', params.DOB)
        params.status && searchParams.set('status', params.status)
        params.panCardNo && searchParams.set('panCardNo', params.panCardNo)
        return axios.put(`${url}`)
}
export const editUserAddress = (params) => {
        const url = new URL(`${Api.EDIT_USER_ADDRESS}?`);
        const searchParams = url.searchParams;
        params.addressLine1 && searchParams.set('addressLine1', params.addressLine1)
        params.addressLine2 && searchParams.set('addressLine2', params.addressLine2)
        params.customerId && searchParams.set('customerId', params.customerId)
        params.addressId && searchParams.set('addressId', params.addressId)
        params.country && searchParams.set('country', params.country)
        params.city && searchParams.set('city', params.city)
        params.state && searchParams.set('state', params.state)
        params.zipCode && searchParams.set('zipCode', params.zipCode)
        return axios.put(`${url}`)
}
export const editCustomerAccount = (params) => {
        const url = new URL(`${Api.EDIT_CUSTOMER_ACCOUNT}?`);
        const searchParams = url.searchParams;
        params.customerAccountId && searchParams.set('customerAccountId', params.customerAccountId)
        params.accountInterestRate && searchParams.set('accountInterestRate', params.accountInterestRate)
        params.allotedAmount && searchParams.set('allotedAmount', params.allotedAmount)
        params.tenure && searchParams.set('tenure', params.tenure)
        return axios.put(`${url}`)
}

// Login
export const resetPassword = (params) => axios.put(`${Api.RESET_PASSWORD}`, params, { headers: resetPasswordAuth })
export const forgotPasswordAdmin = (params) => {
        const url = new URL(`${Api.FORGOT_PASSWORD}?`);
        const searchParams = url.searchParams;
        params.email && searchParams.set('email', params.email)
        return axios.put(`${url}`)
}

// Account
export const editAccount = (params) => axios.put(`${Api.EDIT_ACCOUNT}`, params)
// Service
export const editService = (params) => axios.put(`${Api.EDIT_SERVICE}`, params)
export const editServiceOption = (params) => axios.put(`${Api.SERVICE_OPTION_EDIT}`, params)
// Online Services
export const editOnlineServices = (params) => axios.put(`${Api.ONLINE_SERVICES_EDIT}`, params)
//Settings
export const editCurrentValue = (params) => axios.put(`${Api.EDIT_CURRENT_VALUE}`, params)
//Transaction
export const editTransactionByAdmin = (params) => axios.put(`${Api.EDIT_TRANSACTION}`, params)
//Transaction
export const editGrievance = (params) => axios.put(`${Api.EDIT_GRIEVANCES_LIST}`, params)

// **************Delete**************** //
export const logoutAdmin = (params) => axios.delete(`${Api.LOGOUT}`, params)
export const deleteCustomerByAdmin = (params) => {
        const url = new URL(`${Api.DELETE_USER}?`);
        const searchParams = url.searchParams;
        params.customerId && searchParams.set('customerId', params.customerId)
        params.isDeleted && searchParams.set('isDeleted', params.isDeleted)
        return axios.delete(`${url}`)
}
export const deleteAccount = (params) => {
        const url = new URL(`${Api.DELETE_ACCOUNT}?`);
        const searchParams = url.searchParams;
        params.accountId && searchParams.set('accountId', params.accountId)
        params.isDeleted && searchParams.set('isDeleted', params.isDeleted)
        return axios.delete(`${url}`)
}
export const deleteOtherBankDetails = (params) => {
        const url = new URL(`${Api.DELETE_OTHER_BANK_DETAILS}?`);
        const searchParams = url.searchParams;
        params.otherBankDetailsId && searchParams.set('otherBankDetailsId', params.otherBankDetailsId)
        params.customerId && searchParams.set('customerId', params.customerId)
        return axios.delete(`${url}`)
}
export const editActiveStatus = (params) => {
        const url = new URL(`${Api.EDIT_CUSTOMER_ACCOUNT_STATUS}?`);
        const searchParams = url.searchParams;
        params.customerAccountId && searchParams.set('customerAccountId', params.customerAccountId)
        params.accountStatus && searchParams.set('accountStatus', params.accountStatus)
        return axios.delete(`${url}`)
}
export const deleteCustomerAccount = (params) => {
        const url = new URL(`${Api.DELETE_CUSTOMER_ACCOUNT}?`);
        const searchParams = url.searchParams;
        params.customerAccountId && searchParams.set('customerAccountId', params.customerAccountId)
        params.isDeleted && searchParams.set('isDeleted', params.isDeleted)
        return axios.delete(`${url}`)
}
export const deleteService = (params) => {
        const url = new URL(`${Api.DELETE_SERVICE}?`);
        const searchParams = url.searchParams;
        params.serviceId && searchParams.set('serviceId', params.serviceId)
        params.isDeleted && searchParams.set('isDeleted', params.isDeleted)
        return axios.delete(`${url}`)
}
export const deleteGrievances = (params) => {
        const url = new URL(`${Api.DELETE_GRIEVANCES_LIST}?`);
        const searchParams = url.searchParams;
        params.grievanceId && searchParams.set('grievanceId', params.grievanceId)
        params.isDeleted && searchParams.set('isDeleted', params.isDeleted)
        return axios.delete(`${url}`)
}

